import React from 'react';
import Login from '../../Components/organisms/Login/Login';

const LoginScreen = () => {
  return (
    <div>
        <Login />
    </div>
  )
}

export default LoginScreen