import React from "react";
import SignupOptions from "../../Components/organisms/SignupOptions/SignupOptions";

const SignupOptionsScreen = () => {
    return (
        <div>
            <SignupOptions />
        </div>
    )
}

export default SignupOptionsScreen