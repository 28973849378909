import React from "react";
import Signup from "../../Components/organisms/Signup/Signup";

const SignupScreen = () => {
    return (
        <div>
            <Signup />
        </div>
    )
}

export default SignupScreen